.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 9;
  margin: -30px 0 0 -30px;
  border: 8px solid #ff78782e;
  border-left: 8px solid #db2000;
  border-top: 8px solid #db2000;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*# sourceMappingURL=input.support.auth.style.css.mapss.map */
